import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/chat": [3],
		"/chat/cosmic-questions": [4],
		"/connections": [5],
		"/connections/content": [6],
		"/connections/invite-upsell": [7],
		"/connections/privacy": [8],
		"/content": [9],
		"/dashboard": [10],
		"/design": [11],
		"/design/connections": [12],
		"/design/connections/connections": [13],
		"/design/connections/content": [14],
		"/design/connections/copy-link": [15],
		"/design/connections/create-group": [16],
		"/design/connections/empty": [17],
		"/design/connections/invite-link": [19],
		"/design/connections/invite-upsell": [20],
		"/design/connections/invite": [18],
		"/design/connections/privacy": [21],
		"/design/connections/test-landing": [22],
		"/design/content": [23],
		"/design/cosmic-guide": [24],
		"/design/cosmic-guide/add-connection": [25],
		"/design/cosmic-guide/connection": [26],
		"/design/cosmic-guide/cosmic-guide": [27],
		"/design/cosmic-guide/cosmic-questions": [28],
		"/design/cosmic-guide/history2": [30],
		"/design/cosmic-guide/history": [29],
		"/design/cosmic-guide/library-prompts": [32],
		"/design/cosmic-guide/library": [31],
		"/design/cosmic-guide/select-connection": [33],
		"/design/cosmic-guide/thread": [34],
		"/design/cosmic-guide/welcome-1": [35],
		"/design/cosmic-guide/welcome-2": [36],
		"/design/enneagram": [37],
		"/design/error": [38],
		"/design/free-profile": [39],
		"/design/landing": [40],
		"/design/loading": [41],
		"/design/modal": [42],
		"/design/payment-thank-you": [43],
		"/design/paywall": [44],
		"/design/refresh": [45],
		"/design/sales": [46],
		"/design/settings": [47],
		"/design/signup": [48],
		"/design/signup/change-password": [49],
		"/design/signup/create-password-id": [51],
		"/design/signup/create-password": [50],
		"/design/signup/forgot-password-send": [53],
		"/design/signup/forgot-password": [52],
		"/design/signup/login": [54],
		"/design/soulprint": [55],
		"/design/survey": [56],
		"/design/survey/astrology": [57],
		"/design/survey/enneagram-cards": [58],
		"/design/survey/enneagram-instructions-01": [59],
		"/design/survey/enneagram-instructions-02": [60],
		"/design/survey/enneagram-paragraphs": [61],
		"/design/survey/enneagram-tiebreak-02": [63],
		"/design/survey/enneagram-tiebreak-intro": [64],
		"/design/survey/enneagram-tiebreak": [62],
		"/design/survey/gender": [65],
		"/design/survey/interests-01": [66],
		"/design/survey/interests-02": [67],
		"/design/survey/job": [68],
		"/design/survey/numerology": [69],
		"/design/survey/relationship": [70],
		"/design/survey/retake": [71],
		"/design/survey/signup-email": [73],
		"/design/survey/signup-existinguser": [74],
		"/design/survey/signup-newuser": [75],
		"/design/survey/signup-verify": [76],
		"/design/survey/signup": [72],
		"/design/survey/spiraldynamics-01": [77],
		"/design/survey/spiraldynamics-02": [78],
		"/enneagram": [79],
		"/forgot-password": [80],
		"/free-profile": [81],
		"/free-soulprint": [82],
		"/group": [83],
		"/invite-link": [84],
		"/login": [85],
		"/logout": [86],
		"/new-personality-test": [87],
		"/offer": [88],
		"/offer/cb/sp4": [89],
		"/offer/sp-enneagram": [90],
		"/offer/sp1": [91],
		"/offer/sp2": [92],
		"/offer/sp3": [93],
		"/offer/sp4": [94],
		"/onboard": [95],
		"/payment": [96],
		"/personality-test-1": [98],
		"/personality-test-dev": [99],
		"/personality-test": [97],
		"/premium": [100],
		"/primary": [101],
		"/privacy": [102],
		"/relationship": [103],
		"/reset-password": [104],
		"/secondary": [105],
		"/settings": [106],
		"/soulprint": [107],
		"/teritiary": [108],
		"/terms": [109],
		"/thank-you": [110],
		"/verify": [111],
		"/welcome": [112],
		"/workplace": [113]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';